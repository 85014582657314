import standardConfig from "../config.json";
import { OpenAPI, AuthService, OrgService, PaperService, SlackService, SyncService, GcalService, ZoomService } from "../ampplat_client";
import { OpenAPI as CEOpenAPI, DefaultService as CEDefaultService, PlatformService, HelperService } from "../context_engine_client";
import {ReactNode} from "react";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {Tooltip} from "@mui/material";


const configData = { ...standardConfig};

OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL;
OpenAPI.CREDENTIALS = "include";
OpenAPI.WITH_CREDENTIALS = true;

export const getLoginUrl = () => {
    return configData.LOGIN_URL
}

export const getGoogleLoginUrl = () => {
    return configData.GOOGLE_LOGIN_URL
}

export const getSlackLoginUrl = () => {
    return configData.SLACK_LOGIN_URL
}

export const getZoomLoginUrl = () => {
  return configData.ZOOM_LOGIN_URL
}

export const getLogoutUrl = () => {
    return configData.LOGOUT_URL
}

export const getContextEngineBackendUrl = () => {
    return configData.AMPPLAT_V3_BACKEND_URL
};

export const getFrontendUrl = () => {
    return configData.FRONTEND_URL
}

export const getAuthBackendUrl = () => {
    return configData.AUTH_V1_BACKEND_URL
}

export const getAuthService = () => {
    OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL
    OpenAPI.CREDENTIALS = "include"
    OpenAPI.WITH_CREDENTIALS = true
    return AuthService
}

export const getPaperService = () => {
    OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL
    OpenAPI.CREDENTIALS = "include"
    OpenAPI.WITH_CREDENTIALS = true
    return PaperService
}

export const getOrgService = () => {
    OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL
    OpenAPI.CREDENTIALS = "include"
    OpenAPI.WITH_CREDENTIALS = true
    return OrgService
}

export const getSlackService = () => {
    OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL
    OpenAPI.CREDENTIALS = "include"
    OpenAPI.WITH_CREDENTIALS = true
    return SlackService
}

export const getZoomService = () => {
  OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL
  OpenAPI.CREDENTIALS = "include"
  OpenAPI.WITH_CREDENTIALS = true
  return ZoomService
}

export const getSyncService = () => {
    OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL
    OpenAPI.CREDENTIALS = "include"
    OpenAPI.WITH_CREDENTIALS = true
    return SyncService    
}

export const getGcalService = () => {
  OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL
  OpenAPI.CREDENTIALS = "include"
  OpenAPI.WITH_CREDENTIALS = true
  return GcalService      
}

export const getPrivacyPolicyUrl = () => {
    return configData.Privacy_Policy_URL;
};

export const getContextEngineAgentPlatformService = () => {
    CEOpenAPI.BASE = configData.CONTEXT_ENGINE_V3_BACKEND_URL
    CEOpenAPI.CREDENTIALS = "include"
    CEOpenAPI.WITH_CREDENTIALS = true
    return PlatformService
}

export const getContextEngineAgentHelperService = () => {
    CEOpenAPI.BASE = configData.CONTEXT_ENGINE_V3_BACKEND_URL
    CEOpenAPI.CREDENTIALS = "include"
    CEOpenAPI.WITH_CREDENTIALS = true
    return HelperService
}

export const getContextEngineDefaultService = () => {
    CEOpenAPI.BASE = configData.CONTEXT_ENGINE_V3_BACKEND_URL
    CEOpenAPI.CREDENTIALS = "include"
    CEOpenAPI.WITH_CREDENTIALS = true
    return CEDefaultService
}

export const booleanIcon = (val?: boolean, trueToolTip?: ReactNode, falseToolTip?: ReactNode): ReactNode =>  {
  let res: ReactNode;
  if (val) {
    res = <CheckIcon color="secondary" fontSize="small"/>;
    if (trueToolTip !== undefined) {
      res = <Tooltip title={trueToolTip}>{res}</Tooltip>;
    }
  } else {
    res = <CloseIcon color="error" fontSize="small"/>;
    if (falseToolTip !== undefined) {
      res = <Tooltip title={falseToolTip}>{res}</Tooltip>;
    }
  }

  return res;
}

export const assertExhaustive = (x: never): never => {
  throw new Error("This should never be reached");
}

export const makeTaggedTitleString = (title: string): string => {
  return title.trim() + " | AMP Platform";
}

/**
 * Remove leading indentation from the provided data.
 */
export const dedent = (data: string) => {
  // convert all tabs to two spaces
  data = data.replaceAll("\t", "  ");

  let shortestLeadingSpaces = 1000;
  data.split("\n").forEach((line) => {
    if (line.trim().length == 0) {
      return;
    }
    const match: RegExpMatchArray | null = line.match(/^(?<leadingSpaces> *)[^ $]/);
    const numLeadingSpaces = match?.groups?.leadingSpaces.length || 0;

    if (numLeadingSpaces < shortestLeadingSpaces) {
      shortestLeadingSpaces = numLeadingSpaces;
    }
  });

  if (shortestLeadingSpaces == 0) {
    return data;
  }

  const replaceRegex = new RegExp(`^[ ]{${shortestLeadingSpaces}}`, "g");
  const dedented = data.replace(replaceRegex, "");

  return dedented;
}
