import {Box, Paper, Typography, useTheme} from "@mui/material";
import {Stack} from "@mui/system";
import {PropsWithChildren, ReactNode, useEffect} from "react";
import {Header} from "../Header";
import {InitSnackbar, SnackbarProvider, SnackMessage} from "./agents/SnackMessage";

export type BaseLayoutProps = PropsWithChildren<{
  title?: string,
  useFullArea?: boolean,
  childrenLeft?: ReactNode,
  childrenRight?: ReactNode,
}>;

export const BaseLayout = ({title, useFullArea, childrenLeft, childrenRight, children}: BaseLayoutProps) => {
  const theme = useTheme();

  const snackState = InitSnackbar();

  let toSet: string;
  if (title) {
    toSet = `${title} | AMP Platform`;
  } else {
    toSet = "AMP Platform";
  }
  document.title = toSet;

  return (
    <>
      <Box display="flex" flexDirection="column">
        <SnackbarProvider value={snackState}>
          <Header />
          <Stack
            spacing={theme.spacing(4)}
            useFlexGap={true}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "64px",
              padding: useFullArea ? 0 : 5,
              width: "100%",
            }}
          >
            { title && <Typography variant="h1">{title}</Typography> }

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {childrenLeft}
              <Stack
                spacing={theme.spacing(5)}
                sx={{
                  width: "100%",
                  maxWidth: useFullArea ? "inherit": 1000,
                  alignContent: "stretch",
                  justifyItems: "center",
                  flexGrow: "1"
                }}>
                {children}
              </Stack>
              {childrenRight}
            </Box>
          </Stack>
        </SnackbarProvider>
      </Box>

      <SnackMessage state={snackState} />
    </>
  );
}
