import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Link, Stack} from '@mui/material';

import {PropsWithChildren, } from 'react';
import {CopyTokenToClipboard} from './components/CopyTokenToClipboard';
import { useCurrentUser } from './contexts/UserProvider';


const BareHeader = ({children}: PropsWithChildren<{}>) => {
  return (
    <AppBar id='appBarHeader' position="fixed" sx={{display: "block", zIndex: (theme) => theme.zIndex.drawer + 1}}>
      <Toolbar>
        <Stack
          direction="row"
          spacing={0.5}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100vw',
          }}
        >
          <Link href="/" underline="none" color="inherit">
            <Stack direction="row" spacing={1} style={{alignItems: 'center', minWidth: 250}}>
              <Typography variant="h5" fontFamily="sans-serif"><b>AMP Platform</b></Typography>
            </Stack>
          </Link>

          {children}

        </Stack>
      </Toolbar>
    </AppBar>
  );
}


export const Header = () => {
  const { curUser, logOut } = useCurrentUser();
  if (!curUser) {
    return <BareHeader />;
  }

  return (
    <BareHeader>
      <Stack
        direction="row"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100vw'
        }}
      >
        <>
          <Button variant="text" color="info" href="/">
            Documentation
          </Button>
          <Button variant="text" color="info" href="/api-reference">
            API Reference
          </Button>
          <Button variant="text" color="info" href="/examples">
            Examples
          </Button>
          {/* <Button variant="text" color="info" href="/demos">
                  Hackweek Demos
              </Button> */}
          <Button variant="text" color="info" href="/playground">
            Playground
          </Button>
          <Button variant="text" color="info" href="/connectors">
            Connectors
          </Button>
          <Button variant="text" color="info" href="/agent">
            AI Agents
          </Button>
        </>
      </Stack>
      <Box sx={{pr: 6}}>
        <Stack direction="row" spacing={2} style={{alignItems: 'center', justifyContent: 'flex-end'}}>
          <>
            <CopyTokenToClipboard />
            <Button color="secondary" variant="outlined" onClick={logOut}>
              Logout
            </Button>
          </>
        </Stack>
      </Box>
    </BareHeader>
  );
}
